import React from "react";
import "./style.css";

function ImgBox({ image }) {
  return (
    <div class="img_box_container">
      <div class="box">
        <div class="imgBox">
          <img
            src={image}
            className="d-block mx-lg-auto img-fluid text-center img-thumbnail"
            alt="s"
          />
        </div>
        {/* <div class="content">
          <h2>Image Hover Effects</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore consequat.{" "}
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default ImgBox;
