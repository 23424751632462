import React from "react";
import SEOTags from "../seo";
import { SEO_TYPE } from "../../assets/seoContent";

function CoFounder({ image, name, role, desc, edu = [] }) {
  return (
    <div class="col-lg-4 col-sm-6 g-mb-30 shadow">
      {/* <!-- Figure --> */}
      <figure class="text-center">
        {/* <!-- Figure Image --> */}
        <img class="w-100 h-100 g-mb-20 g-mt-10" src={image} alt="custom alt" />
        {/* <!-- End Figure Image --> */}

        {/* <!-- Figure Info --> */}
        <div class="g-mb-10">
          <h4 class="h5 g-color-black g-mb-5">{name}</h4>
          <em class="d-block g-font-style-normal g-font-size-11 text-uppercase g-color-blue">
            - {role} -
          </em>
        </div>
        {desc?.map((desc) => (
          <p className="lead">{desc}</p>
        ))}
        <ul className="text-start">
          {edu.map((edu) => (
            <li className="text-start">{edu}</li>
          ))}
        </ul>
      </figure>
    </div>
  );
}

function TeamCard({ image, name, role, desc }) {
  return (
    <div class="col-lg-3 col-sm-6 g-mb-30">
      {/* <!-- Figure --> */}
      <figure class="text-center">
        {/* <!-- Figure Image --> */}
        <img class="w-100 g-mb-20" src={image} alt="some alt" />
        {/* <!-- End Figure Image --> */}

        {/* <!-- Figure Info --> */}
        <div class="g-mb-10">
          <h4 class="h5 g-color-black g-mb-5">{name}</h4>
          <em class="d-block g-font-style-normal g-font-size-11 text-uppercase g-color-blue">
            - {role} -
          </em>
        </div>
        <p>{desc}</p>
        {/* <!-- End Info --> */}

        {/* <!-- Figure Social Icons --> */}
        {/* <!-- <ul class="list-unstyled mb-0">
              <li class="list-inline-item mx-0">
                <a
                  class="u-icon-v1 u-icon-size--sm g-font-size-default g-color-gray-dark-v1 g-color-primary--hover"
                  href="#!"
                >
                  <i class="fa fa-facebook"></i>
                </a>
              </li>
              <li class="list-inline-item mx-0">
                <a
                  class="u-icon-v1 u-icon-size--sm g-font-size-default g-color-gray-dark-v1 g-color-primary--hover"
                  href="#!"
                >
                  <i class="fa fa-twitter"></i>
                </a>
              </li>
              <li class="list-inline-item mx-0">
                <a
                  class="u-icon-v1 u-icon-size--sm g-font-size-default g-color-gray-dark-v1 g-color-primary--hover"
                  href="#!"
                >
                  <i class="fa fa-linkedin"></i>
                </a>
              </li>
            </ul> --> */}
        {/* <!-- End Figure Social Icons --> */}
      </figure>
      {/* <!-- End Figure --> */}
    </div>
  );
}

function Teams() {
  return (
    <>
      <SEOTags type={SEO_TYPE.TEAMS} />
      <div class="container g-mb-80" id="team">
        <header class="u-heading-v8-2 text-center g-width-70x--md mx-auto g-mb-80">
          <h1 class="display-4 fw-bold">Our Team</h1>
          {/* <!-- <p class="lead mb-0">
          We do things differently company providing key digital services.
          Focused on helping our clients to build a successful business on web
          and mobile.
        </p> --> */}
        </header>
        {/* <!-- CO - FOUNDERS --> */}
        <header class="u-heading-v8-2 text-center g-width-70x--md mx-auto g-mb-80">
          <h3 class="display-7 fw-bold">Founders</h3>
          {/* <!-- <p class="lead mb-0">
        We do things differently company providing key digital services.
        Focused on helping our clients to build a successful business on web
        and mobile.
      </p> --> */}
        </header>
        <div class="row d-flex align-items-stretch flex-direction-center">
          <div class="col-lg-1 col-sm-6 g-mb-30"></div>
          <CoFounder
            image={require("../../assets/images/nishit_srivastava.png")}
            name={"Nishit Srivastava"}
            role={"CO-FOUNDER"}
            desc={[
              "Professional with diverse exposure in Supply Chain, International Logistics, NOP, Sourcing, Warehousing, Process Excellence",
            ]}
            edu={[
              "PGD in International Business Strategy.",
              "Master in Foreign Trade Management.",
              "Diploma in Export Management.",
              "Bachelor of Commerce",
            ]}
          />
          <div class="col-lg-2 col-sm-6 g-mb-30"></div>
          <CoFounder
            image={require("../../assets/images/manoj_kamble.png")}
            name={"Manoj Kamble"}
            role={"CO-FOUNDER"}
            desc={[
              "Professional experience of two decades in Mechanical, Electrical, Pharma and Supply Chain",
            ]}
            edu={[
              "Adv. Dip in Industrial Safety.",
              "Adv. Dip. in Fire Safety",
              "DBM, SCM, LA-OHSAS IA-QMS & IMS",
            ]}
          />
          <div class="col-lg-1 col-sm-6 g-mb-30"></div>
        </div>
        {/* <!-- Team Block --> */}

        <h3 class="display-7 fw-bold">Advisory Team</h3>
        <p class="lead mb-60">
          Introducing our distinguished advisory team, comprised of highly
          experienced professionals with individual expertise spanning over two
          decades. With their extensive experience, we provide comprehensive
          guidance and solutions in supply chain optimization, and EHS strategy.
          Together, we are committed to delivering exceptional services that
          empower businesses to thrive in a dynamic and competitive landscape.
        </p>
        <div class="row">
          <TeamCard
            image={require("../../assets/images/ritesh_chandra.png")}
            name={"Ritesh Chandra"}
            role={"Legal Advisor"}
            desc={
              "Senior Attorney with two decade plus experience across 25 countries and multiple domain industries including Aviation, Beverages, Power, Telecom, Automobile, Manufacturing, Supply Chain, FMCG, Chemical and Gas, IT."
            }
          />
          <TeamCard
            image={require("../../assets/images/rajkumar_kannan.png")}
            name={"Rajkumar Kannan"}
            role={"Financial Advisor"}
            desc={
              "Chartered Accountant and Finance Leader with expertise in managing profitable businesses, statutory compliance pertaining to varied sectors viz. manufacturing, oil and gas industry."
            }
          />
          <TeamCard
            image={require("../../assets/images/dr_sl_patil.png")}
            name={"Dr. S. L. Patil"}
            role={"Academic Advisor"}
            desc={
              "Executive Director of Captive Power Producers Association, Ex-Secretary General of Thane Belapur Industries association, Principal of Mumbai Career Academy, Advisor to NGO, industries, & Practicing Physician"
            }
          />
          <TeamCard
            image={require("../../assets/images/upendra_sonatakke.png")}
            name={"Upendra Sontakke"}
            role={"CSR Advisor"}
            desc={
              "CEO and Co-founder of Assrescent, GM-CSR(MPBCDC-GoM), Hon. Regional Director of CASI New York, USA. 24 years of leading CSR program for five major organisations. Involved in many social activities and govt. projects."
            }
          />
        </div>
      </div>
    </>
  );
}

export default Teams;
