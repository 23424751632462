import React from "react";

const Header = () => {
  return (
    <div class="container">
      <header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 border-bottom">
        <div className="d-flex flex-wrap align-items-center flex-grow-1">
          <a
            href="/"
            class="d-flex align-items-center mb-2 mb-md-0 text-dark text-decoration-none"
          >
            <img
              src={require("../../assets/images/logo.png")}
              alt="Imag Description"
              width="48"
              height="48"
            />
            {/* <svg
            class="bi me-2"
            width="40"
            height="32"
            role="img"
            aria-label="Warfosx"
          >
            <use xlinkHref="#"></use>
          </svg> */}
          </a>

          <ul class="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
            <li>
              <a href="#about_us" class="nav-link px-2 link-dark">
                About Us
              </a>
            </li>
            <li>
              <a href="#team" class="nav-link px-2 link-dark">
                Team
              </a>
            </li>
            <li>
              <a href="#services" class="nav-link px-2 link-dark">
                Services
              </a>
            </li>
          </ul>
        </div>

        <div class="col-sm-6 col-md-3">
          <i class="icon-call-in d-inline-block display-8 g-mr-10"></i>
          <strong className="nobreak">+91 98330 77524 | +91 98203 36915</strong>
        </div>
        <div class="col-sm-6 col-md-3">
          <i class="icon-envelope d-inline-block display-8 g-mr-10"></i>
          <a href="mailto:contact@warfosxsolutions.com">
            <strong className="nobreak">contact@warfosxsolutions.com</strong>
          </a>
        </div>
      </header>
    </div>
  );
};

export default Header;
