const KEYWORDS =
  "Warfosx, Warfosx Solution, Warfosx Solutions, Warfosx Solution LLP, Warfosx Solutions LLP, EHS Consultancy, Environement solutions, environemnt, health, safety, Supply Chain Management, EHS Audit, EHS, System Documentation EHS, Audit and Assessment EHS, Electrical Audit, Labour Audit, Audit, Warehouse Asset Audit";
const DESCRIPTION =
  "Creating value for Business & People, An organization exploring limitless opportunities and innovation for sustainable business and wellbeing of employees. Welcome to WARFOSX Solutions LLP, your trusted partner in providing comprehensive consultancy services in environment, health, safety (EHS), and supply chain management";
const ROBOT = "index,follow";
const SITE_URL = "http://warfosxsolutions.com/";

const SEO_DETAILS = {
  title: "Warfosx Solutions LLP",
  keywords: KEYWORDS,
  description: DESCRIPTION,
  subject: "Warfosx",
  robots: ROBOT,
  author: "Warfosx Solutions, contact@warfosxsolutions.com",
  url: SITE_URL,
};

export const SEO_TYPE = {
  ROOT: "root",
  ABOUT_US: "about_us",
  TEAMS: "teams",
  SERVICES: "services",
};
export const SEO_CONTENT = {
  [SEO_TYPE.ROOT]: {
    ...SEO_DETAILS,
  },
  [SEO_TYPE.ABOUT_US]: {
    ...SEO_DETAILS,
    keywords: `${KEYWORDS}, About Us EHS, About EHS`,
  },
  [SEO_TYPE.TEAMS]: {
    ...SEO_DETAILS,
    keywords: `${KEYWORDS}, Teams EHS, Team EHS`,
  },
  [SEO_TYPE.SERVICES]: {
    ...SEO_DETAILS,
    keywords: `${KEYWORDS}, Services EHS, Services`,
  },
};
