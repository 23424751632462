import React from "react";
import Header from "../../components/header";
import Hero from "../../components/hero";
import AboutUs from "../../components/aboutUs";
import Teams from "../../components/teams";
import ContactUs from "../../components/contactUs";
import Services from "../../components/services";
import ServiceDetail from "../../components/ServiceDetail";
import CaroselHeader from "../../components/carosel";
import MapView from "../../components/mapview";

const LandingScreen = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <Header />
      <Hero />
      <CaroselHeader />
      <AboutUs />
      <Teams />
      <Services />
      <ServiceDetail />
      <MapView />
      <ContactUs />
    </div>
  );
};

export default LandingScreen;
