import React from "react";
import Icon from "@mdi/react";
import {
  mdiWhatsapp,
  mdiPhoneIncoming,
  mdiEmailArrowRightOutline,
  mdiMapMarkerRadiusOutline,
} from "@mdi/js";

function ContactUs() {
  const onSubmitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    var formdata = new FormData(e.target);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://script.google.com/macros/s/AKfycby0tDDiMppOtUT0X0N7ZaDPPAV2JzIwSG8A5qWbkzjOZi21XR3y1veoN83LbV53uuVDsQ/exec",
      requestOptions
    )
      .then((response) => {
        response.text();
        window.location.reload();
      })
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    document.getElementById("kkk").reset();
  };

  return (
    <div id="contact" class="shortcode-html">
      <footer class="g-bg-black-opacity-0_9 g-color-white text-center g-py-100">
        <div class="container">
          <header class="u-heading-v8-2 text-center g-width-70x--md mx-auto g-mb-80">
            <h2 class="u-heading-v8__title text-uppercase g-font-weight-600 g-mb-25">
              Contact us
            </h2>
            {/* <p class="lead mb-0">
              We do things differently company providing key digital services.
              Focused on helping our clients to build a successful business on
              web and mobile.
            </p> */}
          </header>

          <address class="row g-color-white-opacity-0_8">
            <div class="col-sm-6 col-md-3 g-mb-60">
              <a
                class="g-color-white-opacity-0_8"
                target="_blank"
                rel="noreferrer"
                href="http://maps.google.com/maps?q=19.15838333994058,72.99697608203739"
              >
                <Icon
                  path={mdiMapMarkerRadiusOutline}
                  size={3}
                  color="#EEE"
                  style={{ marginBottom: 25 }}
                />
                <h4 class="small text-uppercase g-mb-5">Address</h4>

                <strong>
                  F-105, Sector-3, Airoli, Thane-Belapur Road, Navi Mumbai,
                  Maharashtra 400708
                </strong>
              </a>
            </div>

            <div class="col-sm-6 col-md-3 g-mb-60">
              <a
                class="g-color-white-opacity-0_8"
                target="_blank"
                rel="noreferrer"
                href="tel:919833077524"
              >
                <Icon
                  path={mdiPhoneIncoming}
                  size={3}
                  color="#EEE"
                  style={{ marginBottom: 25 }}
                />

                <h4 class="small text-uppercase g-mb-5">Phone number</h4>
                <strong>+91 98330 77524 | +91 98203 36915</strong>
              </a>
            </div>

            <div class="col-sm-6 col-md-3">
              <a
                class="g-color-white-opacity-0_8"
                href="mailto:contact@warfosxsolutions.com"
              >
                <Icon
                  path={mdiEmailArrowRightOutline}
                  size={3}
                  color="#EEE"
                  style={{ marginBottom: 25 }}
                />
                <h4 class="small text-uppercase g-mb-5">Email</h4>

                <strong>contact@warfosxsolutions.com</strong>
              </a>
            </div>

            <div class="col-sm-6 col-md-3">
              <a
                class="g-color-white-opacity-0_8"
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send?text=Hi&phone=+919833077524"
              >
                <Icon
                  path={mdiWhatsapp}
                  size={3}
                  color="#EEE"
                  style={{ marginBottom: 25 }}
                />

                <h4 class="small text-uppercase g-mb-5">Whatsapp</h4>
                <strong>+91 98330 77524 | +91 98203 36915</strong>
              </a>
            </div>
          </address>

          <form name="submit-to-google-sheet" id="kkk" onSubmit={onSubmitForm}>
            <div class="row">
              <div class="col-md-7">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group g-mb-20">
                      <input
                        id="inputGroup10_1"
                        class="form-control g-bg-transparent g-bg-transparent--focus g-brd-white-opacity-0_5 g-brd-white--focus g-color-white g-rounded-50 g-px-20 g-py-12"
                        type="text"
                        placeholder="Name"
                        name="name"
                      />
                    </div>
                    <div class="form-group g-mb-20">
                      <input
                        id="inputGroup10_2"
                        class="form-control g-bg-transparent g-bg-transparent--focus g-brd-white-opacity-0_5 g-brd-white--focus g-color-white g-rounded-50 g-px-20 g-py-12"
                        type="tel"
                        placeholder="Phone"
                        name="phone"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group g-mb-20">
                      <input
                        id="inputGroup1_3"
                        class="form-control g-bg-transparent g-bg-transparent--focus g-brd-white-opacity-0_5 g-brd-white--focus g-color-white g-rounded-50 g-px-20 g-py-12"
                        type="email"
                        name="email"
                        placeholder="Email"
                      />
                    </div>

                    <div class="form-group g-mb-20">
                      <input
                        id="inputGroup1_4"
                        class="form-control g-bg-transparent g-bg-transparent--focus g-brd-white-opacity-0_5 g-brd-white--focus g-color-white g-rounded-50 g-px-20 g-py-12"
                        type="text"
                        name="subject"
                        placeholder="Subject"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-5">
                <div class="form-group g-mb-20">
                  <textarea
                    id="inputGroup11_5"
                    class="form-control g-resize-none g-bg-transparent g-bg-transparent--focus g-brd-white-opacity-0_5 g-brd-white--focus g-color-white g-rounded-20 g-px-20 g-py-10"
                    rows="5"
                    placeholder="Message"
                    name="message"
                  ></textarea>
                </div>

                <div class="text-right">
                  <button
                    class="btn u-btn-white g-font-size-11 text-uppercase g-font-weight-700 g-rounded-50 g-px-35 g-py-15"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </footer>
    </div>
  );
}

export default ContactUs;
