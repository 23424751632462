import React from "react";
import SEOTags from "../seo";
import { SEO_TYPE } from "../../assets/seoContent";

function ServiceCard({ image, title }) {
  return (
    <div class="col-md-4 g-mb-30">
      <a
        class="js-fancybox d-block u-block-hover u-bg-overlay u-bg-overlay--v1 g-bg-black-opacity-0_5--after"
        href="#services-audits"
        data-fancybox="lightbox-gallery-13-1-hidden"
        data-src={image}
        data-caption="Lightbox Gallery"
      >
        <img
          class="img-fluid u-block-hover__main--zoom-v1"
          src={image}
          alt="service card "
          style={{
            minHeight: "330px",
            maxHeight: "330px",
            objectFit: "cover",
          }}
        />
        <strong class="h5 d-block w-100 u-bg-overlay__inner g-absolute-centered g-px-20 g-font-weight-400 g-color-white g-mt-10">
          {title}
        </strong>
      </a>
    </div>
  );
}

function Services() {
  return (
    <>
      <SEOTags type={SEO_TYPE.SERVICES} />
      <div class="container g-mb-80" id="services">
        <header class="u-heading-v8-2 text-center g-width-70x--md mx-auto g-mb-80">
          <h1 class="display-4 fw-bold">Our Services</h1>
          {/* <!-- <p class="lead mb-0">
          We do things differently company providing key digital services.
          Focused on helping our clients to build a successful business on web
          and mobile.
        </p> --> */}
        </header>
        <div class="row text-center">
          <ServiceCard
            image={require("../../assets/images/img-11.jpg")}
            title={"Audits & Assessment​"}
          />
          <ServiceCard
            image={require("../../assets/images/audit.png")}
            title={"Training & Skill building"}
          />
          <ServiceCard
            image={require("../../assets/images/plumbing.png")}
            title={"Operation & Process Safety"}
          />
          <ServiceCard
            image={require("../../assets/images/document.png")}
            title={"System Documentation"}
          />
          <ServiceCard
            image={require("../../assets/images/supply_chain.png")}
            title={"Supply Chain Solutions​"}
          />
        </div>
      </div>
    </>
  );
}

export default Services;
