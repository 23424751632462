import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const SLIDE_1 = require("../../assets/images/carosel/slide_1.png");
const SLIDE_2 = require("../../assets/images/carosel/slide_2.png");
const SLIDE_3 = require("../../assets/images/carosel/slide_3.png");
const SLIDE_4 = require("../../assets/images/carosel/slide_4.png");
const SLIDE_5 = require("../../assets/images/carosel/slide_5.png");
const SLIDE_6 = require("../../assets/images/carosel/slide_6.png");
const SLIDE_7 = require("../../assets/images/carosel/slide_7.png");
const SLIDE_8 = require("../../assets/images/carosel/slide_8.png");
const SLIDE_9 = require("../../assets/images/carosel/slide_9.png");

const SLIDES = [
  SLIDE_1,
  SLIDE_2,
  SLIDE_3,
  SLIDE_4,
  SLIDE_5,
  SLIDE_6,
  SLIDE_7,
  SLIDE_8,
  SLIDE_9,
];

function CaroselHeader() {
  const onChange = () => {};
  const onClickItem = () => {};
  const onClickThumb = () => {};

  return (
    <div class="container col-xxl-12 px-4 py-5">
      <Carousel
        showArrows
        onChange={onChange}
        onClickItem={onClickItem}
        onClickThumb={onClickThumb}
        showStatus
        // showIndicators
        infiniteLoop
        showThumbs={false}
        useKeyboardArrows
        autoPlay
        stopOnHover
        swipeable
        dynamicHeight
        emulateTouch
        autoFocus={false}
        thumbWidth={100}
        selectedItem={0}
        interval={5000}
        transitionTime={500}
        swipeScrollTolerance={5}
      >
        {SLIDES.map((slide, index) => (
          <div key={index?.toString()}>
            <img src={slide} alt={"slide figure " + index} />
            {/* <p className="legend">Legend 1</p> */}
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default CaroselHeader;
