import React from "react";

function MapView() {
  return (
    <div id="wrapper-9cd199b9cc5410cd3b1ad21cab2e54d3">
      <div id="map-9cd199b9cc5410cd3b1ad21cab2e54d3"></div>
    </div>
  );
}

export default MapView;
