import React from "react";
import ImgBox from "../imgbox";

function ServiceDetail() {
  // const isInViewport1 = useIsInViewport(view1Ref);
  // const isInViewport2 = useIsInViewport(view2Ref);
  // const isInViewport3 = useIsInViewport(view3Ref);
  // const isInViewport4 = useIsInViewport(view4Ref);
  // const isInViewport5 = useIsInViewport(view5Ref);

  // useEffect(() => {
  //   anime({
  //     targets: "#immm1",
  //     translateX: isInViewport1 ? 0 : 250,
  //     duration: 3000,
  //   });
  // }, [isInViewport1]);

  // useEffect(() => {
  //   anime({
  //     targets: "#immm2",
  //     translateX: isInViewport2 ? 0 : -250,
  //     duration: 3000,
  //   });
  // }, [isInViewport2]);

  // useEffect(() => {
  //   anime({
  //     targets: "#immm3",
  //     translateX: isInViewport3 ? 0 : 250,
  //     duration: 3000,
  //   });
  // }, [isInViewport3]);

  // useEffect(() => {
  //   anime({
  //     targets: "#immm4",
  //     translateX: isInViewport4 ? 0 : -250,
  //     duration: 3000,
  //   });
  // }, [isInViewport4]);

  // useEffect(() => {
  //   anime({
  //     targets: "#immm5",
  //     translateX: isInViewport5 ? 0 : 250,
  //     duration: 3000,
  //   });
  // }, [isInViewport5]);

  return (
    <div>
      {/* <!-- service section 1 --> */}
      <div class="container g-mb-60" id="services-audits">
        <header class="u-heading-v8-2 text-center g-width-70x--md mx-auto g-mb-80">
          <h2 class="display-5 fw-bold">Audits & Assessment</h2>
          {/* <!-- <p class="lead mb-0">
          We do things differently company providing key digital services.
          Focused on helping our clients to build a successful business on web
          and mobile.
        </p> --> */}
        </header>
        <div class="row text-center">
          <div class="col-md-8 g-pr-50--md g-mb-30 g-mb-0--md">
            <p className="lead text-start">
              Audits and assessments to elevate organization’s performance from
              being merely "the best" to achieving excellence. Through the
              recalibration process, we intend to optimize your operations,
              enhance efficiency, and foster a culture of continuous
              improvement.
            </p>
            <p className="lead text-start">
              Our Audits and assessments will provide invaluable insights and
              serve as catalysts for driving positive change, enabling you to
              reach new heights of organizational excellence
            </p>
            <p className="lead text-start">
              Our process of implementing audit is distinct, systematically
              structured, extensively brain stormed, people engaging and
              delivered in coherent manner to achieve audit objectives. We
              perform customized audits and assessment specific to activities,
              or processes.
            </p>
            <p className="lead text-start">
              We work beyond boundaries, we deliver beyond expectation.
            </p>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-lg-1">
                    <img
                      src={require("../../assets/images/security-shield.png")}
                      alt="SAFETY AUDIT"
                      class="img-fluid"
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  </div>
                  <div class="col-lg-7">
                    <p class="fw-bolder fs-5 text">INDUSTRIAL SAFETY AUDIT</p>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-lg-1">
                    <img
                      src={require("../../assets/images/fire-extinguisher.png")}
                      alt="SAFETY AUDIT"
                      class="img-fluid"
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  </div>
                  <div class="col-lg-7">
                    <p class="fw-bolder fs-5 text">FIRE SAFETY AUDIT</p>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-lg-1">
                    <img
                      src={require("../../assets/images/clipboard.png")}
                      alt="SAFETY AUDIT"
                      class="img-fluid"
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  </div>
                  <div class="col-lg-7">
                    <p class="fw-bolder fs-5 text">ELECTRICAL AUDIT</p>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-lg-1">
                    <img
                      src={require("../../assets/images/asset_audit.png")}
                      alt="SAFETY AUDIT"
                      class="img-fluid"
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  </div>
                  <div class="col-lg-7">
                    <p class="fw-bolder fs-5 text">WAREHOUSE ASSET AUDIT</p>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row align-items-center">
                  <div class="col-lg-1">
                    <img
                      src={require("../../assets/images/employee.png")}
                      alt="SAFETY AUDIT"
                      class="img-fluid"
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  </div>
                  <div class="col-lg-7">
                    <p class="fw-bolder fs-5 text">LABOUR AUDIT</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="col-md-4">
            {/* <img
              ref={view1Ref}
              id="immm1"
              class="w-100 g-px-50"
              style={
                {
                  // transform: "translateX(250px)",
                }
              }
              src={require("../../assets/images/img-11.jpg")}
              alt="Imag Description"
            /> */}
            <ImgBox image={require("../../assets/images/img-11.jpg")} />
          </div>
        </div>
      </div>

      {/* <!-- service section 2--> */}
      <div class="container g-mb-60" id="services-2">
        <header class="u-heading-v8-2 text-center g-width-70x--md mx-auto g-mb-80">
          <h2 class="display-5 fw-bold">Training & Skill Building</h2>
          {/* <!-- <p class="lead mb-0">
          We do things differently company providing key digital services.
          Focused on helping our clients to build a successful business on web
          and mobile.
        </p> --> */}
        </header>
        <div class="row text-center">
          <div class="col-md-6">
            {/* <img
              ref={view2Ref}
              id="immm2"
              class="w-100 g-px-50"
              style={
                {
                  // transform: "translateX(-250px)",
                }
              }
              src={require("../../assets/images/audit.png")}
              alt="Imag Description"
            /> */}
            <ImgBox image={require("../../assets/images/training.png")} />
          </div>
          <div class="col-md-6 g-pr-50--md g-mb-30 g-mb-0--md">
            <p className="lead text-start">
              When we talk about training, WARFOSX is inspired by “Potter”. It
              is act of creating “Best” from raw.
            </p>
            <p className="lead text-start">
              Shaping human mind is strenuous task as human mind is pre-occupied
              with many thoughts but with systematic approach and right input,
              toughest nut get crack and we see blossom.
            </p>
            <p className="lead text-start">
              We at WARFOSX, believe in knowledge transfer, information sharing
              and learning together. For us, training is an invaluable session,
              where information flows uninterruptedly to learners satisfaction.
            </p>
            <p className="lead text-start">
              Our training offerings encompass both traditional and customized
              programs tailored to meet the unique needs of organizations. We
              consistently update our training module data bank with insights
              gained from client engagements, ensuring that our offerings remain
              current and relevant.
            </p>
          </div>

          <div class="col-md-6 g-pr-50--md g-mb-30 g-mb-0--md">
            <div class="col-md-12 g-pr-50--md g-mb-30 g-mb-0--md">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        Safety Management System
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">Building Construction</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">Height Work</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">Electrical Work</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        Hazardous Waste Management
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        Laboratory Chemical Handling
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        Fire Extinguishers & Use
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        Occupational Health Hazards
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">Work Permit System</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        Manual Material Handling
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        EHS Statutory Compliance and much more
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 g-pr-50--md g-mb-30 g-mb-0--md">
            <ImgBox image={require("../../assets/images/training2.png")} />
          </div>
        </div>
      </div>

      {/* <!-- service section 3 --> */}
      <div class="container g-mb-60" id="services-3">
        <header class="u-heading-v8-2 text-center g-width-70x--md mx-auto g-mb-80">
          <h2 class="display-5 fw-bold">Operation & Process Safety</h2>
          {/* <!-- <p class="lead mb-0">
          We do things differently company providing key digital services.
          Focused on helping our clients to build a successful business on web
          and mobile.
        </p> --> */}
        </header>
        <div class="row text-center">
          <div class="col-md-7 g-pr-50--md g-mb-30 g-mb-0--md">
            <p className="lead text-start">
              Process safety is an expertly crafted and disciplined assessment
              module that focuses on the prevention and mitigation of critical
              accidents, explosions, large fires, and occupational health
              hazards caused by chemicals and hazardous substances.
            </p>
            <p className="lead text-start">
              We safeguard industries by conducting comprehensive studies to
              identify, assess, and mitigate unknown, dormant, and significant
              risks.
            </p>

            <div class="col-md-12 g-pr-50--md g-mb-30 g-mb-0--md">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        Process Safety gap assessment
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        PHA – HAZID, HAZOP, QRA, Fault Tree Analysis
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        Fire Explosion Risk Assessment
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        Consequences Analysis - MCLS
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        Hazardous Area Classification
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-5">
            {/* <img
              ref={view3Ref}
              id="immm3"
              class="w-100 g-px-50"
              style={
                {
                  // transform: "translateX(250px)",
                }
              }
              src={require("../../assets/images/plumbing.png")}
              alt="Imag Description"
            /> */}
            <ImgBox image={require("../../assets/images/plumbing.png")} />
          </div>
        </div>
      </div>

      {/* <!-- service section 4--> */}
      <div class="container g-mb-60" id="services-4">
        <header class="u-heading-v8-2 text-center g-width-70x--md mx-auto g-mb-80">
          <h2 class="display-5 fw-bold">System Documentation</h2>
          {/* <!-- <p class="lead mb-0">
          We do things differently company providing key digital services.
          Focused on helping our clients to build a successful business on web
          and mobile.
        </p> --> */}
        </header>
        <div class="row text-center">
          <div class="col-md-6">
            {/* <img
              ref={view4Ref}
              id="immm4"
              class="w-100 g-px-50"
              style={
                {
                  // transform: "translateX(-250px)",
                }
              }
              src={}
              alt="Imag Description"
            /> */}
            <ImgBox image={require("../../assets/images/document.png")} />
          </div>
          <div class="col-md-6 g-pr-50--md g-mb-30 g-mb-0--md">
            <p className="lead text-start">
              The success of an organization lies within the concealed framework
              of its written processes, procedures, instructions, formats,
              checklists, and guidelines.
            </p>
            <p className="lead text-start">
              Our team comprehends the depth and criticality of these elements
              and their impact on day-to-day operations. We firmly believe that
              a well-thought-out, organized, and meticulously designed
              documentation manual serves as the pathway to success.
            </p>
            <p className="lead text-start">
              We are here to provide support to your organization in drafting
              and creating this essential documentation manual.
            </p>
          </div>
        </div>
        <section class="section bg-light">
          <div class="container">
            <div class="row">
              {/* <div class="col-lg-6">
                <img
                  src={require("../../assets/images/idea.jpg")}
                  alt="EHS Management"
                  class="img-fluid"
                />
              </div> */}
              <div class="col-lg-6">
                <h2>EHS & F Management Document</h2>
                <p>
                  Beyond statutory obligation, we cover other critical aspects
                  which are mostly ignored.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Process Document & Procedures Section --> */}
        <section class="section">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <h2>Process Document & Procedures</h2>
                <p>
                  Inclusion of latest technology, best practices & innovation is
                  our forte.
                </p>
              </div>
              {/* <div class="col-lg-6">
                <img
                  src={require("../../assets/images/tech.jpg")}
                  alt="Process Document"
                  class="img-fluid"
                />
              </div> */}
            </div>
          </div>
        </section>

        {/* <!-- Supply Chain Operation Management Section --> */}
        <section class="section bg-light">
          <div class="container">
            <div class="row">
              {/* <div class="col-lg-6">
                <img
                  src={require("../../assets/images/target.jpg")}
                  alt="Supply Chain Management"
                  class="img-fluid"
                />
              </div> */}
              <div class="col-lg-6">
                <h2>Supply Chain Operation Management</h2>
                <p>
                  Practical & effective approach to complex situations helps
                  deliver the best results for business.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- service section 5 --> */}
      <div class="container g-mb-60" id="services-5">
        <header class="u-heading-v8-2 text-center g-width-70x--md mx-auto g-mb-80">
          <h2 class="display-5 fw-bold">Supply Chain</h2>
          {/* <!-- <p class="lead mb-0">
          We do things differently company providing key digital services.
          Focused on helping our clients to build a successful business on web
          and mobile.
        </p> --> */}
        </header>
        <div class="row text-center">
          <div class="col-md-6 g-pr-50--md g-mb-30 g-mb-0--md">
            <p className="lead text-start">
              Supply Chain is the crucial link between your organization and
              customers, acting as the backbone of your business. Any disruption
              can have paralyzing effects, causing significant challenges.
            </p>
            <p className="lead text-start">
              Our experienced team of supply chain professionals understands the
              importance of efficient, cost-effective, and well-designed
              processes. We are here to support you in managing internal and
              external issues, ensuring uninterrupted operations and overcoming
              challenges. From streamlining processes to enhancing efficiency,
              we are committed to delivering customized strategies and practical
              solutions to drive success and improve overall operational
              performance.
            </p>
            <p className="lead text-start">
              Let us keep your supply chain vibrant, fueling your success.
            </p>
          </div>

          <div class="col-md-6">
            {/* <img
              ref={view5Ref}
              id="immm5"
              // class="w-200 g-px-50"
              width={"100%"}
              style={
                {
                  // transform: "translateX(250px)",
                }
              }
              src={require("../../assets/images/warehouse/warehouse2.png")}
              alt="Imag Description"
            /> */}
            <ImgBox
              image={require("../../assets/images/warehouse/warehouse2.png")}
            />
          </div>
          <div class="row text-center lg-mt-60">
            <div class="col-12 col-md-4 g-pr-50--md g-mb-30 g-mb-0--md">
              <ImgBox image={require("../../assets/images/supply.png")} />
            </div>
            <div class="col-md-8 g-pr-50--md g-mb-30 g-mb-0--md">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-lg-1">
                      <img
                        src={require("../../assets/images/warehouse.png")}
                        alt="warehouse"
                        class="img-fluid"
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        Warehouse Mngt. & Operation
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center ">
                    <div class="col-lg-1">
                      <img
                        src={require("../../assets/images/distribution.png")}
                        alt="distribution"
                        class="img-fluid"
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        International & Domestic Logistics
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-lg-1">
                      <img
                        src={require("../../assets/images/inventory.png")}
                        alt="inventory"
                        class="img-fluid"
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        Inventory Audit, Cycle Count
                      </p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-lg-1">
                      <img
                        src={require("../../assets/images/shipment.png")}
                        alt="laisoning"
                        class="img-fluid"
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">Warehouse Liaisoning</p>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col-lg-1">
                      <img
                        src={require("../../assets/images/implementation.png")}
                        alt="improvement"
                        class="img-fluid"
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                    <div class="col-lg-7">
                      <p class="fw-bolder fs-5 text">
                        Network Optimization & Process Improvement
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceDetail;
