import React from "react";
import "./style.css";
import SEOTags from "../seo";
import { SEO_TYPE } from "../../assets/seoContent";

function AboutUs() {
  return (
    <>
      <SEOTags type={SEO_TYPE.ABOUT_US} />
      <div class="pt-5 my-5 text-center border-bottom">
        <div class="image-text-overlay text-middle-center bg-img">
          <div style={{ height: "40px" }} />
          <p className="px-4 ">
            {/* <span class="overlay-title">middle center</span> */}
            <h1 class="display-4 fw-bold ">About Us</h1>
            <br />
            {/* <span class="overlay-subtext">This is subtext.</span> */}
            <div class="col-lg-6 mx-auto">
              <p class="lead mb-4 stroked">
                <strong>
                  Welcome to WARFOSX Solutions LLP, your trusted partner in
                  providing comprehensive consultancy services in environment,
                  health, safety (EHS), and supply chain management. With our
                  deep expertise and commitment to excellence, we assist
                  companies in navigating the complex landscape of environmental
                  regulations, ensuring workplace safety, and optimizing supply
                  chain operations. Our tailored solutions empower businesses to
                  achieve sustainable practices, mitigate risks, and drive
                  operational efficiency, positioning them for long-term success
                  in a rapidly evolving business environment.
                </strong>
              </p>
              <p class="lead mb-4 stroked">
                <strong>
                  We recognize that responsible business practices are essential
                  for a thriving future, and we actively work with our clients
                  to create environmentally and socially conscious solutions.
                  Our commitment to sustainable development is reflected in our
                  approach to consulting.
                </strong>
              </p>
              <div style={{ height: "40px" }} />
            </div>
          </p>

          <div class="overlay-white"></div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
