import React from "react";
import { Helmet } from "react-helmet";
import { SEO_CONTENT } from "../../assets/seoContent";

const SEOTags = ({ type = "root" }) => {
  const seoDetails = SEO_CONTENT[type] || SEO_CONTENT.root;
  const { title, keywords, description, subject, robots, author, url } =
    seoDetails;

  return (
    <Helmet>
      <meta name="title" content={title} />
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <meta name="subject" content={subject} />
      <meta name="robots" content={robots} />
      <meta name="author" content={author} />
      <meta name="url" content={url} />
      <meta name="identifier-URL" content={url} />
      <link rel="canonical" href={url} />
    </Helmet>
  );
};

export default SEOTags;
