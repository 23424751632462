import React from "react";
import SEOTags from "../seo";

function Hero() {
  if (true)
    return (
      <>
        <SEOTags />
        <div class="container col-xxl-12 px-4 py-5">
          <div class="row flex-lg-row align-items-center g-5 py-5">
            <div class="col-12 col-sm-8 col-lg-6 text-center">
              <img
                src={require("../../assets/images/hero.png")}
                class="d-block mx-lg-auto img-fluid text-center"
                alt="Hero"
                // width="700"
                // height="500"
                loading="lazy"
              />
            </div>
            <div class="col-lg-6 text-center">
              {/* <h1 class="display-5 fw-bold lh-1 mb-3">
              WARFOSX
              <h2 style={{ display: "inline" }}>
                <sub>SOLUTIONS LLP</sub>
              </h2>
            </h1>

            <p
              className="text-center"
              style={{
                color: "#72c02c",
              }}
            >
              <u>Creating value for Business & People</u>
            </p> */}
              <img
                src={require("../../assets/images/hero/logo.png")}
                class="d-block mx-lg-auto img-fluid text-center"
                alt="Hero"
                width="700"
                height="500"
                loading="lazy"
              />

              <p className="lead text-center">
                An organization exploring limitless opportunities and innovation
                for sustainable business and wellbeing of employees
              </p>
            </div>
          </div>
        </div>
      </>
    );

  // return (
  //   <div class="container-fluid d-lg-flex mb-2 g-mb-80 g-mt-80">
  //     <div
  //       class="col-md-12 col-lg-6 d-flex flex-column align-items-center justify-content-center"
  //       style={{ backgroundColor: "#f9f9f9", minHeight: "300px" }}
  //     >
  //       <div class="company-container g-mb-20">
  //         <h3 class="g-color-black g-font-size-60--lg mb-0 g-font-weight-600">
  //           WARFOSX SOLUTIONS LLP
  //         </h3>
  //       </div>
  //       <em class="info-v3-3__category g-font-style-normal g-font-size-11 text-uppercase g-color-blue">
  //         Creating value for Business & People
  //       </em>
  //       <p class="text-center g-mt-30">
  //         An organization exploring limitless opportunities and innovation for
  //         sustainable business andwellbeing of employees
  //       </p>
  //     </div>
  //     <div class="col-md-12 col-lg-6">
  //       <img
  //         src={require("../../assets/images/hero.png")}
  //         alt=""
  //         class="img-fluid h-100 w-100"
  //       />
  //     </div>
  //   </div>
  // );
}

export default Hero;
